<template>
    <div>
        <pre>
            {{ response }}
        </pre>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'PointClickCareIntegrationTest',
  data: function() {
      return {
          response: '',
      }
  },
  mounted: function() {

      this.testPointClickCare()
  },
  methods: {
      async testPointClickCare() {

          const response = await axios.get('testing/pointclickcare');

          this.response = response

      },
  },
  
}
</script>
